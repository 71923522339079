import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOutletContext, useParams } from 'react-router-dom'
import { Box, Button, Chip, Collapse, Grid, IconButton, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import ProjectService from 'api/services/project.service'
import RiskService from 'api/services/risk.service'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import TuneIcon from '@mui/icons-material/Tune'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { BarChart, pieArcLabelClasses, PieChart } from '@mui/x-charts'
import DataTable from 'components/DataTable/DataTable'
import DropdownDownload from 'components/DropdownDownload/DropdownDownload'
import LightTooltip from 'components/LightTooltip/LightTooltip'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import { useAuth } from 'hooks/useAuth'
import { useProjectRoles } from 'hooks/useProjectRoles'
import { useAlert } from 'hooks/useAlert'
import { riskAreas, riskMode, riskStatus, riskUrgency } from 'utils/constants'
import SideModalRiskFilters from 'views/Projects/components/SideModalRiskFilters/SideModalRiskFilters'
import ModalRisksSettings from 'views/Projects/components/ModalRisksSettings/ModalRisksSettings'
import { riskColors } from 'utils/styles'
import { initializeFilters } from '../../../../reduxStore/reducer/riskFiltersManagementSlice'
import ModalRisk from '../../components/ModalRisk/ModalRisk'
import Styles from './_Risks.module.scss'

const Risks = () => {
  const { setAlert } = useAlert()
  const { projectId } = useParams()
  const { project } = useOutletContext()
  const { isSystemObserver } = useAuth()
  const { isProjectObserver } = useProjectRoles()
  const dispatch = useDispatch()
  const filters = useSelector(store => store.riskFiltersManagement)
  const [riskPagination, setRiskPagination] = useState({
    page: 0,
    size: 10,
    search: '',
    sort: '',
    order: ''
  })
  const [totalRisks, setTotalRisks] = useState(0)
  const [searchBarValue, setSearchBarValue] = useState('')
  const [initialLoading, setInitialLoading] = useState(true)
  const [loadingStats, setLoadingStats] = useState(true)
  const [showStats, setShowStats] = useState(true)

  const [risksList, setRisksList] = useState([])
  const [selectedRisk, setSelectedRisk] = useState()
  const [sidemodalRisk, setSidemodalRisk] = useState({ show: false, actionType: '' })
  const [modalDelete, setModalDelete] = useState({ show: false, loading: false })
  const [riskSettings, setRiskSettings] = useState()
  const [isAllLoaded, setIsAllLoaded] = useState(false)
  const [showSideModalFilters, setShowSideModalFilters] = useState(false)
  const [showModalRisksSettings, setShowModalRisksSettings] = useState(false)
  const [downloading, setDownloading] = useState(false)

  const [risksStats, setRisksStats] = useState({ status: [] })
  const [problemsStats, setProblemsStats] = useState({ status: [] })

  const handleActions = (type, value) => {
    setSelectedRisk(value)
    if (type === 'edit' || type === 'view') {
      setSidemodalRisk({ show: true, actionType: type === 'edit' ? 'update' : 'view' })
    } else if (!isSystemObserver && !isProjectObserver) {
      setModalDelete({ show: true, loading: false })
    }
  }

  const headers = [
    {
      header: 'Severidad',
      key: 'severity_value',
      ordering: true,
      cellType: 'chip'
    },
    {
      header: 'ID',
      key: 'id_by_project',
      cellType: 'text'
    },
    {
      header: 'Fecha registro',
      key: 'creation_date',
      ordering: true,
      cellType: 'date'
    },
    {
      header: 'Titulo',
      key: 'title',
      ordering: true,
      cellType: 'text'
    },
    {
      header: 'Fase',
      key: 'phase_id',
      cellType: 'text'
    },
    {
      header: 'Área (EDR)',
      key: 'risk_area_id',
      cellType: 'text'
    },
    {
      header: 'Fecha límite',
      key: 'deadline_date',
      cellType: 'date'
    },
    {
      header: 'Días restantes',
      key: 'deadline_days',
      cellType: 'text'
    },
    {
      header: 'Riesgo / Problema',
      key: 'mode',
      cellType: 'text'
    },
    // {
    //   header: 'Urgencia',
    //   key: 'risk_urgency_id',
    //   cellType: 'text'
    // },
    {
      header: 'Status',
      key: 'risk_status_id',
      cellType: 'text'
    },
    {
      header: 'Acciones',
      key: 'actions',
      cellType: 'action_buttons',
      action: handleActions
    }
  ]

  const getSeverityColor = value => {
    return value <= riskSettings.riskSeverityLimits.low
      ? riskColors.green
      : value <= riskSettings.riskSeverityLimits.medium
        ? riskColors.yellow
        : riskColors.red
  }

  const orderData = data => {
    const actions = isSystemObserver || isProjectObserver ? 'action_buttons_observer' : 'action_buttons'
    const orderedData = data.map(element => {
      const row = headers.map(header => {
        const value =
          header.key === 'phase_id'
            ? project.phases.find(phase => phase.id === element[header.key]).name
            : header.key === 'risk_area_id'
              ? riskAreas.find(area => area.value === element[header.key]).label
              : header.key === 'mode'
                ? riskMode.find(mode => mode.value === element[header.key]).label
                : header.key === 'risk_urgency_id'
                  ? riskUrgency.find(mode => mode.value === element[header.key]).label
                  : header.key === 'risk_status_id'
                    ? riskStatus.find(mode => mode.value === element[header.key]).label
                    : header.key === 'severity_value'
                      ? { label: element[header.key], color: getSeverityColor(element[header.key]) }
                      : header.key === 'deadline_date' || header.key === 'deadline_days'
                        ? element.has_deadline
                          ? element[header.key]
                          : 'No aplica'
                        : element[header.key]
        const obj = {
          cellType: header.key === 'actions' ? actions : header.cellType,
          key: header.key,
          value: value,
          action: (...values) => header.action(...values, element),
          props: {}
        }

        return obj
      })
      return row
    })
    return orderedData
  }

  const handleSortTable = header => {
    setRiskPagination(prevState => ({
      ...prevState,
      sort: header,
      order: header !== prevState.sort ? 'asc' : prevState.order === '' || prevState.order === 'asc' ? 'desc' : 'asc'
    }))
  }

  const getRisks = async () => {
    try {
      const resp = await ProjectService.findRisksFilters(projectId, {
        ...filters,
        ...riskPagination,
        page: riskPagination.page + 1
      })
      setInitialLoading(false)
      setRisksList(orderData(resp.data))
      setTotalRisks(resp.total)
      setShowSideModalFilters(false)
    } catch (error) {
      console.error(error)
      setAlert(true, 'error', 'Algo salió mal al listar Riesgos, intenta de nuevo más tarde')
    }
  }

  const getRisksStats = async () => {
    setLoadingStats(true)
    const { problems, risks } = await ProjectService.findRisksStats(projectId)
    setProblemsStats({
      ...problems,
      status: [
        { label: 'Activos', value: problems.active },
        { label: 'Inactivos', value: problems.inactive },
        { label: 'Descartados', value: problems.discarded }
      ]
    })
    setRisksStats({
      ...risks,
      status: [
        { label: 'Activos', value: risks.active },
        { label: 'Inactivos', value: risks.inactive },
        { label: 'Descartados', value: risks.discarded }
      ]
    })
    setLoadingStats(false)
  }

  const getRisksSettings = async () => {
    const resp = await ProjectService.findRiskSettings(projectId)
    setRiskSettings(resp)
  }

  const handleSaveRisk = result => {
    setSidemodalRisk(prevState => ({ ...prevState, show: false }))
    if (result === 'success') {
      let message = ''
      if (sidemodalRisk.actionType === 'create') {
        message = 'Riesgo creado con éxito'
      } else {
        message = 'Riesgo guardado con éxito'
      }
      getRisks()
      getRisksStats()
      setAlert(true, 'success', message)
    } else {
      setAlert(true, 'error', 'Ocurrió un error al guardar el Riesgo, intenta de nuevo más tarde')
    }
  }

  const handleSaveRisksSettings = (result, data) => {
    setShowModalRisksSettings(false)
    if (result === 'success') {
      setRiskSettings(data)

      getRisks()
      getRisksStats()
      setAlert(true, 'success', 'Configuración guardad y aplicada con éxito')
    } else {
      setAlert(true, 'error', 'Ocurrió un error al guardar los cambios, intenta de nuevo más tarde')
    }
  }

  const handleCreateRisk = () => {
    setSidemodalRisk({ show: true, actionType: 'create' })
  }

  const handleConfirmDeleteRisk = async () => {
    setModalDelete(prevState => ({ ...prevState, loading: true }))
    try {
      await RiskService.delete(selectedRisk.id)
      setModalDelete({ show: false, loading: false })
      getRisks()
      getRisksStats()
      setAlert(true, 'success', 'Riesgo eliminado con éxito')
    } catch (error) {
      setModalDelete({ show: false, loading: false })
      setAlert(true, 'error', 'Ocurrió un error al eliminar el Riesgo, intenta de nuevo más tarde')
    }
  }

  const handleFilters = async () => {
    setRiskPagination(prevState => ({ ...prevState, page: 0 }))
  }

  useEffect(() => {
    if (riskSettings) {
      getRisks()
      setIsAllLoaded(true)
    }
  }, [riskSettings])

  useEffect(() => {
    getRisksSettings()
    getRisksStats()
    dispatch(initializeFilters())
  }, [])

  useEffect(() => {
    if (isAllLoaded) {
      getRisks()
    }
  }, [isProjectObserver])

  const [filtersQty, setFiltersQty] = useState(0)

  useEffect(() => {
    let qty = 0
    Object.keys(filters).forEach(key => {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) qty += 1
      } else if (filters[key] !== null && filters[key] !== undefined) {
        qty += 1
      }
    })
    setFiltersQty(qty)
  }, [filters])

  const handlePageChange = (e, value) => {
    setRiskPagination(prevState => ({ ...prevState, page: value }))
  }

  const handleRowsPerPageChange = e => {
    setRiskPagination(prevState => ({ ...prevState, size: e.target.value, page: 0 }))
  }

  const handleClearSearchBar = () => {
    setSearchBarValue('')
    setRiskPagination(prevState => ({ ...prevState, search: '' }))
  }

  const handleDownload = async format => {
    setDownloading(true)
    try {
      await ProjectService.downloadRisksReport(projectId, {
        ...filters,
        ...riskPagination,
        page: riskPagination.page + 1,
        format
      })
      setDownloading(false)
    } catch (error) {
      setDownloading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    if (!initialLoading) getRisks()
  }, [riskPagination])

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <h2>Riesgos</h2>
          <LightTooltip title="Configuración de Riesgos" arrow>
            <span>
              <IconButton disabled={!isAllLoaded || downloading} onClick={() => setShowModalRisksSettings(true)}>
                <SettingsIcon />
              </IconButton>
            </span>
          </LightTooltip>
        </Box>
        <Button
          variant="outlined"
          startIcon={showStats ? <VisibilityOffIcon /> : <VisibilityIcon />}
          onClick={() => setShowStats(!showStats)}>
          {showStats ? 'Ocultar estadísticas' : 'Mostrar estadísticas'}
        </Button>
      </Box>
      <Collapse in={showStats}>
        <Box sx={{ display: 'flex', padding: '1rem 1.5rem 1.5rem 0' }}>
          <Grid container spacing={1} rowSpacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item md={4} sm={6} xs={12}>
              <Box sx={{ textAlign: 'center', fontWeight: '600', marginBottom: '.5rem' }}>Riesgos - Problemas</Box>
              <PieChart
                loading={loadingStats}
                colors={['#87c8da', 'lightcoral']}
                margin={{ left: -10 }}
                series={[
                  {
                    arcLabel: item => `${item.value}`,
                    arcLabelMinAngle: 35,
                    arcLabelRadius: '60%',
                    highlightScope: { fade: 'global', highlight: 'item' },
                    data: loadingStats
                      ? []
                      : [
                          { label: ' Riesgos ', value: risksStats.total },
                          { label: ' Problemas ', value: problemsStats.total }
                        ]
                  }
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold'
                  }
                }}
                height={130}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box sx={{ textAlign: 'center', fontWeight: '500', marginBottom: '.5rem' }}>Riesgos</Box>
              <PieChart
                loading={loadingStats}
                colors={['#08b077', '#f3ab36', '#aeaca6']}
                margin={{ left: -10 }}
                series={[
                  {
                    arcLabel: item => `${item.value}`,
                    arcLabelMinAngle: 5,
                    arcLabelRadius: '60%',
                    innerRadius: 20,
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                    data: loadingStats ? [] : risksStats.status
                  }
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold'
                  }
                }}
                height={130}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box sx={{ textAlign: 'center', fontWeight: '500', marginBottom: '.5rem' }}>Problemas</Box>
              <PieChart
                loading={loadingStats}
                colors={['#08b077', '#f3ab36', '#aeaca6']}
                margin={{ left: -10 }}
                series={[
                  {
                    arcLabel: item => `${item.value}`,
                    arcLabelMinAngle: 5,
                    arcLabelRadius: '60%',
                    innerRadius: 20,
                    highlightScope: { fade: 'global', highlight: 'item' },
                    faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                    data: loadingStats ? [] : problemsStats.status
                  }
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold'
                  }
                }}
                height={130}
              />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: 'center', fontWeight: '500', marginBottom: '.5rem' }}>Registros Activos</Box>
              <BarChart
                sx={{ '& .MuiBarLabel-root': { fontWeight: 700, fill: 'white' } }}
                loading={loadingStats}
                barLabel="value"
                colors={['#825495', '#4c5e91']}
                margin={{ right: 150, top: 10, bottom: 20 }}
                xAxis={[{ scaleType: 'band', data: ['Bajo', 'Medio', 'Alto'] }]}
                series={
                  loadingStats
                    ? []
                    : [
                        {
                          data: [risksStats?.active_low, risksStats?.active_medium, risksStats?.active_high],
                          label: 'Riesgos',
                          highlightScope: { highlight: 'series', fade: 'global' }
                        },
                        {
                          data: [problemsStats?.active_low, problemsStats?.active_medium, problemsStats?.active_high],
                          label: 'Problemas',
                          highlightScope: { highlight: 'series', fade: 'global' }
                        }
                      ]
                }
                slotProps={{
                  legend: {
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: 0
                  }
                }}
                height={180}
              />
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: 'center', fontWeight: '500', marginBottom: '.5rem' }}>Todos los Riesgos</Box>
              <BarChart
                sx={{ '& .MuiBarLabel-root': { fontWeight: 700, fill: 'white' } }}
                loading={loadingStats}
                barLabel="value"
                colors={['#825495', '#4c5e91']}
                margin={{ right: 150, top: 10, bottom: 20 }}
                xAxis={[{ scaleType: 'band', data: ['Bajo', 'Medio', 'Alto'] }]}
                series={
                  loadingStats
                    ? []
                    : [
                        {
                          data: [risksStats?.low, risksStats?.medium, risksStats?.high],
                          label: 'Riesgos',
                          highlightScope: { highlight: 'series', fade: 'global' }
                        },
                        {
                          data: [problemsStats?.low, problemsStats?.medium, problemsStats?.high],
                          label: 'Problemas',
                          highlightScope: { highlight: 'series', fade: 'global' }
                        }
                      ]
                }
                slotProps={{
                  legend: {
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: 0
                  }
                }}
                height={180}
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      <Box sx={{ marginBottom: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <TextField
            className={Styles.searchbar}
            sx={{
              '& .MuiInputBase-root': {
                paddingLeft: '.5rem',
                paddingRight: '.5rem'
              }
            }}
            size="small"
            placeholder="Buscar por título"
            value={searchBarValue}
            onChange={e => setSearchBarValue(e.target.value)}
            onKeyDown={e =>
              e.key === 'Enter' && setRiskPagination(prevState => ({ ...prevState, search: searchBarValue }))
            }
            InputProps={{
              startAdornment: <SearchIcon sx={{ marginRight: '.25rem', color: '#999' }} />,
              endAdornment: (
                <IconButton className={Styles.show_clear_button} size="small" onClick={handleClearSearchBar}>
                  <ClearIcon />
                </IconButton>
              )
            }}
          />
          <Button
            sx={{ marginLeft: '.5rem' }}
            variant="outlined"
            onClick={() => setRiskPagination(prevState => ({ ...prevState, search: searchBarValue }))}>
            Buscar
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <DropdownDownload
            sx={{ marginRight: '1rem' }}
            handleDownload={handleDownload}
            downloading={downloading}
            disabled={!isAllLoaded}
          />
          <Button
            disabled={!isAllLoaded || downloading}
            variant="outlined"
            color="primary"
            sx={{ marginRight: '1rem' }}
            startIcon={<TuneIcon />}
            onClick={() => setShowSideModalFilters(true)}>
            {!isAllLoaded ? (
              'Cargando'
            ) : (
              <>
                Filtros
                {filtersQty !== 0 && (
                  <Chip
                    label={filtersQty}
                    color="primary"
                    size="small"
                    sx={{ marginLeft: '.5rem', fontSize: '.75rem' }}
                  />
                )}
              </>
            )}
          </Button>
          {!isSystemObserver && !isProjectObserver && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateRisk}
              disabled={!isAllLoaded || downloading}>
              {!isAllLoaded ? 'Cargando' : 'Nuevo Riesgo'}
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ margin: '2rem 0' }}>
        <DataTable
          headers={headers}
          data={risksList}
          disableEditAction={!isAllLoaded}
          isPaginated
          loading={initialLoading}
          total={totalRisks}
          rowsPerPage={riskPagination.size}
          page={riskPagination.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          sortHeaderHandler={handleSortTable}
        />
      </Box>
      <ModalRisk
        projectId={projectId}
        phases={project.phases}
        open={sidemodalRisk.show}
        actionType={sidemodalRisk.actionType}
        selectedRisk={selectedRisk}
        riskProbabilities={riskSettings?.riskProbabilities}
        riskCostImpacts={riskSettings?.riskCostImpacts}
        riskTimeImpacts={riskSettings?.riskTimeImpacts}
        riskSeverityLimits={riskSettings?.riskSeverityLimits}
        onCancel={() => setSidemodalRisk(prevState => ({ ...prevState, show: false }))}
        onConfirm={handleSaveRisk}
      />
      <ModalRisksSettings
        projectId={projectId}
        open={showModalRisksSettings}
        project={project}
        riskSettings={riskSettings}
        onCancel={() => setShowModalRisksSettings(false)}
        onConfirm={handleSaveRisksSettings}
      />
      <SideModalRiskFilters
        open={showSideModalFilters}
        riskSeverityLimits={riskSettings?.riskSeverityLimits}
        phases={project.phases}
        onCancel={() => setShowSideModalFilters(false)}
        onConfirm={handleFilters}
      />
      <ModalDelete
        entity="Riesgo"
        element={selectedRisk}
        open={modalDelete.show}
        onCancel={() => setModalDelete(prevState => ({ ...prevState, show: false }))}
        onConfirm={handleConfirmDeleteRisk}
        loading={modalDelete.loading}
      />
    </>
  )
}

export default Risks
