import { createSlice } from '@reduxjs/toolkit'

export const riskFiltersManagementSlice = createSlice({
  name: 'riskFiltersManagement',
  initialState: {
    creation_date_start: null,
    creation_date_end: null,
    severity: [],
    status: [1],
    type: undefined,
    mode: undefined,
    urgency: [],
    phases: [],
    areas: []
  },
  reducers: {
    setFilters: (state, action) => {
      state[action.payload.key] = action.payload.value
    },
    initializeFilters: state => {
      state.creation_date_start = null
      state.creation_date_end = null
      state.severity = []
      state.status = [1]
      state.type = undefined
      state.mode = undefined
      state.urgency = []
      state.phases = []
      state.areas = []
    },
    resetFilters: state => {
      state.creation_date_start = null
      state.creation_date_end = null
      state.severity = []
      state.status = []
      state.type = undefined
      state.mode = undefined
      state.urgency = []
      state.phases = []
      state.areas = []
    }
  }
})

export const { setFilters, initializeFilters, resetFilters } = riskFiltersManagementSlice.actions

export default riskFiltersManagementSlice.reducer
